import React from "react";
import classNames from "classnames";

import BlurUpImage from "components/blurUpImage/blurUpImage";
import TextButton from "components/textButton/textButton";
import { AuthorsLinkList } from "components/authorsLinkList/authorsLinkList";
import { FullDatoImage } from "lib/types/datoBlocks";
import { SponsorBanner } from "lib/types/common";
import { Author } from "lib/types/article";

type MixPanelData = {
  [key: string]: any;
};

export type BaseCardProps = {
  title: string;
  url: string;
  excerpt?: string;
  withExcerpt?: boolean;
  leftLabel?: {
    name: string;
    url?: string;
  };
  rightLabel?: {
    name: string;
    url?: string;
  };
  publishTimestamp?: number;
  image?: Omit<FullDatoImage, "id">;
  withImage?: boolean;
  isSponsored?: boolean;
  sponsorDetails?: Pick<
    SponsorBanner,
    "name" | "sponsorshipType" | "logo" | "url"
  >;
  variant: "small" | "large";
  onTrackingEventClicked?: (
    data: MixPanelData,
    type: "url" | "labelLeft"
  ) => Promise<void>;
  onContextMenu?: (
    data: MixPanelData,
    type: "url" | "labelLeft"
  ) => Promise<void>;
  trackingComponentName?: string;
  linkLabel?: string | undefined;
  mainLinkClasses?: string;
  leftLabelClasses?: string;
  cardClasses?: string;
  truncateAuthors?: boolean;
  authors: Author[];
  authorsLabel?: string | React.ReactNode;
  contentSubscriptionLevel?: "pro" | "free";
  authorsLineTestId?: string;
  proLabelTestId?: string;
  dotSeparatorTestId?: string;
};

const BaseCard = ({
  variant,
  title,
  url,
  leftLabel,
  rightLabel,
  publishTimestamp,
  onTrackingEventClicked,
  onContextMenu,
  linkLabel,
  withImage = variant === "large",
  image,
  authors,
  isSponsored,
  sponsorDetails,
  truncateAuthors,
  excerpt,
  withExcerpt,
  mainLinkClasses,
  cardClasses,
  authorsLabel,
  contentSubscriptionLevel = "free",
  leftLabelClasses,
  trackingComponentName,
  authorsLineTestId,
  proLabelTestId,
  dotSeparatorTestId,
}: BaseCardProps) => {
  const onTrackClick = async (
    data: MixPanelData,
    type: "url" | "labelLeft"
  ) => {
    if (onTrackingEventClicked) {
      await onTrackingEventClicked(data, type);
    }
  };
  const onTrackContextMenu = async (
    data: MixPanelData,
    type: "url" | "labelLeft"
  ) => {
    if (onContextMenu) {
      await onContextMenu(data, type);
    }
  };

  const largeTitleLimit = (titleLength: number): boolean => {
    return title.length > titleLength && variant === "large";
  };
  const isProContent = contentSubscriptionLevel === "pro";

  return (
    <div className={classNames("relative h-100 w-100", cardClasses)}>
      <a
        aria-label={linkLabel}
        href={url}
        className={classNames(
          "peer absolute inset-0 -z-0 outline-none",
          mainLinkClasses
        )}
        onClick={async () =>
          await onTrackClick(
            {
              "Click link": url,
              "Click text": title,
            },
            "url"
          )
        }
        onContextMenu={async () => {
          await onTrackContextMenu(
            {
              "Click link": url,
              "Click text": title,
            },
            "url"
          );
        }}
      >
        <span
          className={"sr-only"}
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
      </a>
      <div
        className={classNames(
          "flex flex-col justify-between",
          "h-100 bg-white p-4 transition-shadow",
          !isProContent
            ? [
                "[box-shadow:inset_0_0_0_2px_rgba(244,183,206,0)]",
                "peer-hover:[box-shadow:inset_0_0_0_2px_rgba(244,183,206,1)]",
                "peer-active:bg-[#FFF0F5] peer-active:[box-shadow:inset_0_0_0_2px_rgba(244,183,206,0)]",
                "peer-focus:outline-none peer-focus:[box-shadow:inset_0_0_0_2px_rgba(216,28,95,1)]",
              ]
            : [
                "[box-shadow:inset_0_0_0_2px_rgba(51,80,219,0)]",
                "peer-hover:[box-shadow:inset_0_0_0_2px_rgba(51,80,219,0.4)]",
                "peer-active:bg-[rgba(51,80,219,0.1)] peer-active:[box-shadow:inset_0_0_0_2px_rgba(51,80,219,0)]",
                "peer-focus:outline-none peer-focus:[box-shadow:inset_0_0_0_2px_rgba(51,80,219,1)]",
              ]
        )}
      >
        <div className={"flex flex-col"}>
          <div className={"mb-2 flex items-center justify-between"}>
            <div className={"flex items-center gap-[10px]"}>
              {leftLabel && (
                <TextButton
                  size={"medium"}
                  colour={isProContent ? "pro-blue" : "cerise"}
                  href={leftLabel.url}
                  className={classNames(leftLabelClasses, "z-1 relative")}
                  onClick={async () =>
                    await onTrackClick(
                      {
                        Component: trackingComponentName,
                        "Click link": leftLabel.url,
                        "Click text": leftLabel.name,
                      },
                      "labelLeft"
                    )
                  }
                  onContextMenu={async () =>
                    await onTrackContextMenu(
                      {
                        Component: trackingComponentName,
                        "Click link": leftLabel.url,
                        "Click text": leftLabel.name,
                      },
                      "labelLeft"
                    )
                  }
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: leftLabel.name,
                    }}
                  />
                </TextButton>
              )}
              {rightLabel && (
                <>
                  {leftLabel && (
                    <span data-testid={dotSeparatorTestId}>
                      <svg
                        width="4"
                        height="4"
                        viewBox="0 0 4 4"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="2" cy="2" r="2" fill="#999999" />
                      </svg>
                    </span>
                  )}
                  <span className={"mr-2 font-bold text-[14px] leading-[120%]"}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: rightLabel.name,
                      }}
                    />
                  </span>
                </>
              )}
            </div>
            {publishTimestamp && (
              <p
                className={
                  "whitespace-nowrap text-[14px] leading-4 text-mono-30"
                }
              >
                {new Date(publishTimestamp).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </p>
            )}
          </div>
          <div className={"mb-[10px] flex justify-between lg:mb-0"}>
            <div className={"flex flex-col"}>
              <h2
                className={classNames(
                  "font-recklessBold leading-[130%] transition-colors",
                  {
                    "mr-2 text-[16px] md:mr-4 lg:text-[28px]":
                      variant === "large",
                    "mr-2 text-[16px]": variant === "small",
                  }
                )}
              >
                <span
                  className={classNames({
                    "line-clamp-3": variant === "small",
                    "line-clamp-5": variant === "large",
                  })}
                  dangerouslySetInnerHTML={{
                    __html: title,
                  }}
                />
              </h2>
              {withExcerpt && excerpt && (
                <p
                  className={classNames(
                    "mt-2 line-clamp-2 text-[14px] leading-[130%]",
                    {
                      "lg:hidden": largeTitleLimit(76),
                      "min-[1100px]:max-lg:hidden": largeTitleLimit(86),
                      "min-[980px]:max-[1100px]:hidden": largeTitleLimit(70),
                      "min-[800px]:max-[980px]:hidden": largeTitleLimit(65),
                      "min-[736px]:max-[800px]:hidden": largeTitleLimit(45),
                      "min-[580px]:max-[736px]:hidden": largeTitleLimit(102),
                      "min-[480px]:max-[580px]:hidden": largeTitleLimit(76),
                      "min-[440px]:max-[480px]:hidden": largeTitleLimit(68),
                      "min-[370px]:max-[440px]:hidden": largeTitleLimit(60),
                      "max-[370px]:hidden": largeTitleLimit(52),
                    }
                  )}
                >
                  {excerpt}
                </p>
              )}
            </div>
            {(variant === "large" || withImage) && image && image.url && (
              <div
                className={classNames("ml-1 shrink-0", {
                  "aspect-[110/73] h-100 min-h-[73px] w-[30%] min-w-[110px] max-w-[180px] md:max-w-100 lg:aspect-[257/170] lg:h-[170px] lg:min-h-0 lg:w-100 lg:max-w-[254px]":
                    variant === "large",
                  "aspect-[110/73] h-100 min-h-[73px] w-[30%] min-w-[110px] max-w-[140px] md:max-w-100":
                    variant === "small",
                })}
              >
                <BlurUpImage
                  src={image.url}
                  alt={(typeof image.alt === "string" && image.alt) || title}
                  width={image.width}
                  height={image.height}
                  blurDataURL={image.blurUpThumb || undefined}
                  className={"pointer-events-none h-100 w-100 object-cover"}
                  sizes={"(max-width: 767px) min(90vw,460px), 300px"}
                />
              </div>
            )}
          </div>
        </div>
        <div
          className={classNames({
            truncate: truncateAuthors,
          })}
          data-testid={authorsLineTestId}
        >
          {isSponsored && sponsorDetails?.name ? (
            <span
              className={"text-[14px] uppercase leading-[120%] text-mono-30"}
            >
              <span>{sponsorDetails.sponsorshipType || "Sponsored by"} </span>
              <span
                dangerouslySetInnerHTML={{
                  __html: sponsorDetails.name,
                }}
              />
            </span>
          ) : (
            <>
              {authorsLabel ? (
                authorsLabel
              ) : (
                <span
                  className={
                    "text-[14px] uppercase leading-[120%] text-mono-30"
                  }
                >
                  <span>By {""}</span>
                  <AuthorsLinkList
                    authors={authors}
                    plainText={true}
                    secondaryTextClassName={"text-inherit"}
                  />
                </span>
              )}
            </>
          )}
        </div>
        {isProContent && (
          <div
            className={"pointer-events-none absolute bottom-0 right-0 z-0"}
            data-testid={proLabelTestId}
          >
            <div className={"relative h-[58px] w-[58px] p-[6px]"}>
              <div
                className="absolute left-0 top-0 h-0 w-0 border-b-[58px] border-l-[58px] border-b-pro-blue border-l-transparent"
                aria-hidden={true}
              />
              <div
                className={
                  "absolute bottom-[6px] right-[6px] font-bold text-[12px] text-white"
                }
              >
                Pro
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BaseCard;
