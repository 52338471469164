import React from "react";

import type { ArticleShort } from "lib/types/article";
import BaseCard from "../baseCard/baseCard";

type MixPanelData = {
  [key: string]: any;
};

export type ArticleCardProps = ArticleShort & {
  variant: "small" | "large";
  onTrackingEventClicked?: (
    data: MixPanelData,
    type: "url" | "sector"
  ) => Promise<void>;
  onContextMenu?: (data: MixPanelData, type: "url" | "sector") => Promise<void>;
  withImage?: boolean;
  linkLabel?: string | undefined;
  truncateAuthors?: boolean;
  withExcerpt?: boolean;
  mainLinkClasses?: string;
  sectorClasses?: string;
  cardClasses?: string;
  authorsLabel?: string | React.ReactNode;
};

const ArticleCard = ({
  variant,
  title,
  url,
  category,
  sector,
  publishTimestamp,
  onTrackingEventClicked,
  onContextMenu,
  linkLabel,
  withImage = variant === "large",
  image,
  authors,
  isSponsored,
  sponsorDetails,
  sponsorshipType,
  truncateAuthors,
  excerpt,
  withExcerpt,
  mainLinkClasses,
  sectorClasses,
  cardClasses,
  authorsLabel,
  isProContent,
}: ArticleCardProps) => {
  const onTrackClick = async (
    data: MixPanelData,
    type: "url" | "labelLeft"
  ) => {
    const eventTypeMapped = {
      url: "url",
      labelLeft: "sector",
    }[type] as "url" | "sector";

    if (onTrackingEventClicked) {
      await onTrackingEventClicked(data, eventTypeMapped);
    }
  };
  const onTrackContextMenu = async (
    data: MixPanelData,
    type: "url" | "labelLeft"
  ) => {
    const eventTypeMapped = {
      url: "url",
      labelLeft: "sector",
    }[type] as "url" | "sector";

    if (onContextMenu) {
      await onContextMenu(data, eventTypeMapped);
    }
  };

  return (
    <BaseCard
      contentSubscriptionLevel={isProContent ? "pro" : "free"}
      title={title}
      url={url}
      excerpt={excerpt}
      withExcerpt={withExcerpt}
      image={{
        title: image.caption,
        url: image.base,
        alt: typeof image.alt === "string" ? image.alt : "",
        width: image.fullWidth,
        height: image.fullHeight,
        blurUpThumb: image.placeholder || null,
      }}
      withImage={withImage}
      variant={variant}
      authors={authors}
      truncateAuthors={truncateAuthors}
      authorsLabel={authorsLabel}
      onContextMenu={onTrackContextMenu}
      onTrackingEventClicked={onTrackClick}
      leftLabel={
        sector && {
          name: sector.name,
          url: sector.url,
        }
      }
      rightLabel={
        category && {
          name: category.name,
          url: category.url,
        }
      }
      publishTimestamp={publishTimestamp}
      linkLabel={linkLabel}
      isSponsored={isSponsored}
      sponsorDetails={{
        name: sponsorDetails?.name,
        url: sponsorDetails?.url,
        logo: sponsorDetails?.logo,
        sponsorshipType: sponsorshipType,
      }}
      mainLinkClasses={mainLinkClasses}
      leftLabelClasses={sectorClasses}
      cardClasses={cardClasses}
      authorsLineTestId={"articleCard__authors"}
      proLabelTestId={"articleCard__proLabel"}
      dotSeparatorTestId={"articleCard__dot-separator"}
      trackingComponentName={"ArticleCard"}
    />
  );
};

export default ArticleCard;
