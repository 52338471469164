import { useState } from "react";
import Image from "next/image";
import classNames from "classnames";
import { TRANSPARENT_PIXEL } from "lib/constants/shared";

type BlurUpImageProps = {
  src: string;
  alt: string;
  className?: string;
  width: number;
  height: number;
  blurDataURL?: string;
  sizes?: string;
  quality?: number;
};

const BlurUpImage = ({
  src,
  alt,
  className,
  width,
  height,
  blurDataURL,
  sizes,
  quality,
  ...props
}: BlurUpImageProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  function imageLoader({
    src,
    width,
    quality,
  }: {
    src: string;
    width: number;
    quality: number;
  }): string {
    const url = new URL(src);
    const params = url.searchParams;

    params.set("auto", params.getAll("auto").join(",") || "format");
    params.set("fit", params.get("fit") || "max");
    params.set("w", params.get("w") || width.toString());

    if (quality) {
      params.set("q", quality.toString());
    }

    return url.href;
  }

  return (
    <Image
      onLoad={() => setIsLoaded(true)}
      loader={imageLoader}
      src={src}
      alt={alt}
      width={width}
      height={height}
      quality={quality || 75}
      blurDataURL={blurDataURL || TRANSPARENT_PIXEL}
      placeholder="blur"
      className={classNames(
        className,
        `will-change-[filter] [transition:filter_0.25s]`,
        {
          "blur-[10px]": !isLoaded,
        }
      )}
      sizes={
        sizes || "(max-width: 768px) 100vw, (max-width: 1200px) 100vw, 100vw"
      }
      {...props}
    />
  );
};

export default BlurUpImage;
