import classNames from "classnames";

import type { Author } from "lib/types/article";

type AuthorLinkListProps = {
  authors: Author[];
  onAuthorClick?: (author: Author, link: string) => Promise<void>;
  gaClassName?: string;
  /**
   * Renders only text without any links
   */
  plainText?: boolean;
  /**
   * Class styles for secondary texts such as commas and conjunctions
   */
  secondaryTextClassName?: string;
};

export const AuthorsLinkList = ({
  authors,
  onAuthorClick,
  gaClassName = "ga-author-link",
  plainText = false,
  secondaryTextClassName = "font-normal text-black-rock",
}: AuthorLinkListProps) => {
  const numberAuthors = authors.length;

  if (numberAuthors === 0) return null;

  return (
    <>
      {authors.map((author, i) => {
        const link = "/author/" + author.niceName + "/";
        return (
          <span key={i}>
            {!plainText && onAuthorClick ? (
              <a
                className={gaClassName}
                onClick={async () => await onAuthorClick(author, link)}
                href={link}
              >
                {author.name}
              </a>
            ) : (
              <span>{author.name}</span>
            )}

            {/* Show "and" before the last author, else if more than two authors show comma */}
            {(numberAuthors > 1 && i === numberAuthors - 2 && (
              <span className={classNames(secondaryTextClassName)}>
                {" "}
                and&nbsp;
              </span>
            )) ||
              (i < numberAuthors - 1 && (
                <span className={classNames(secondaryTextClassName)}>
                  ,&nbsp;
                </span>
              ))}
          </span>
        );
      })}
    </>
  );
};
