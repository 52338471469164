import React from "react";
import type { TextButtonProps } from "../textButton";

type TextButtonInnerProps = Pick<
  TextButtonProps,
  "withIcon" | "icon" | "labelIcon"
> & {
  children: string | React.ReactNode;
};

const TextButtonInner = ({
  children,
  withIcon,
  icon,
  labelIcon = "Go to page",
}: TextButtonInnerProps) => {
  const defaultIcon = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 4L13 10L7 16" stroke="currentColor" strokeWidth="2" />
    </svg>
  );

  return (
    <>
      <span className={"inline underline [text-underline-position:under]"}>
        {children}
      </span>
      {withIcon && (
        <span
          className={
            "ml-1 inline-flex h-[20px] w-[20px] items-center justify-center"
          }
          aria-label={labelIcon}
        >
          {icon || defaultIcon}
        </span>
      )}
    </>
  );
};

export default TextButtonInner;
