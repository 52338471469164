import React from "react";
import classNames from "classnames";

import TextButtonInner from "./textButtonInner/textButtonInner";

// This is in case we will have more sizes, including relative in future
type Size = "medium";

type CommonProps = Partial<React.ButtonHTMLAttributes<HTMLButtonElement>> &
  Partial<React.LinkHTMLAttributes<Omit<HTMLAnchorElement, "href">>>;

export type TextButtonProps = CommonProps & {
  children: string | React.ReactNode;
  size?: Size | [Size, Size, Size];
  colour?: "cerise" | "black" | "pro-blue";
  component?: "button" | "link";
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  disabled?: boolean;
  withIcon?: boolean;
  icon?: React.ReactNode;
  labelIcon?: string;
  target?: string;
};

const baseSizes = {
  medium: "text-[14px]",
};

const prepareSizesForResponsive = (size: Size, prefix: string) => {
  return baseSizes[size]
    .split(" ")
    .map((el) => `${prefix}:${el}`)
    .join(" ");
};

const TextButton = ({
  children,
  component = "link",
  href,
  colour = "cerise",
  size = "medium",
  className,
  withIcon,
  icon,
  labelIcon,
  ...props
}: TextButtonProps) => {
  let responsiveSizes: string | null = null;

  const commonStyles =
    "inline-flex font-bold leading-[130%] bg-transparent transition-colors";

  const styles = {
    cerise:
      "text-[#D81C5F] hover:bg-[#D81C5F1A] active:bg-transparent focus:outline-none focus:ring focus:ring-cerise disabled:text-mono-30",
    black:
      "text-black-rock hover:bg-[#0202311A] active:bg-transparent focus:outline-none focus:ring focus:ring-black-rock disabled:text-mono-30",
    "pro-blue":
      "text-pro-blue hover:bg-[#3350DB1A] active:bg-transparent focus:outline-none focus:ring focus:ring-pro-blue disabled:text-mono-30",
  }[colour];

  if (typeof size === "object" && size.length === 3) {
    const mobileStyles = baseSizes[size[0]];
    const tabletStyles = prepareSizesForResponsive(size[1], "md");
    const desktopStyles = prepareSizesForResponsive(size[2], "lg");

    responsiveSizes = `${mobileStyles} ${tabletStyles} ${desktopStyles}`;
  } else {
    responsiveSizes = baseSizes[size];
  }

  const componentProps = {
    className: classNames(commonStyles, styles, className, responsiveSizes),
  };

  if (component === "button") {
    return (
      <button {...componentProps} {...props}>
        <TextButtonInner withIcon={withIcon} icon={icon} labelIcon={labelIcon}>
          {children}
        </TextButtonInner>
      </button>
    );
  }

  if (!href) {
    return null;
  }

  return (
    <a
      href={href}
      referrerPolicy={"strict-origin-when-cross-origin"}
      {...componentProps}
      {...props}
    >
      <TextButtonInner withIcon={withIcon} icon={icon} labelIcon={labelIcon}>
        {children}
      </TextButtonInner>
    </a>
  );
};

export default TextButton;
